<script setup lang="ts">
const { closeModal } = useModalStore();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const $api = useApi();

const props = defineProps({
  food: {
    type: Object,
    default: () => {},
    required: true,
  },
  foodPortion: {
    type: Object,
    default: () => {},
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
});

const selectedPortionId = ref();
const foodPortionData = reactive({
  label: props.foodPortion.overrideLabel ?? undefined,
  quantity: props.foodPortion.overrideQuantity ?? undefined,
});

watch(
  () => props.foodPortion,
  () => {
    selectedPortionId.value = props.foodPortion.portion.id;
  },
  { immediate: true },
);

async function handleUpdatePortionFromFood() {
  await $api.put(`/food/${props.food.id}/portions/${props.foodPortion.id}`, {
    overrideLabel: foodPortionData.label,
    overrideQuantity: foodPortionData.quantity,
  });
  props.onConfirm(props.foodPortion.id);
  closeModal();
}

async function handleDeletePortionFromFood() {
  await $api.delete(`/food/${props.food.id}/portions/${props.foodPortion.id}`);
  props.onConfirm(props.foodPortion.id);
  closeModal();
}
</script>

<template>
  <BaseModal
    :title="`Editer une portion à l'aliment ${props.food.label}`"
    :width="900"
  >
    <BaseInput
      type="text"
      label="Override Label"
      v-model="foodPortionData.label"
      :placeholder="props.foodPortion.label"
      required
    ></BaseInput>

    <BaseInput
      type="number"
      label="Override Quantité"
      v-model="foodPortionData.quantity"
      required
    ></BaseInput>

    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="closeModal" />

      <BaseButton
        text="Supprimer"
        type="warning"
        @click="handleDeletePortionFromFood"
      />
      <BaseButton text="Mettre à jour" @click="handleUpdatePortionFromFood" />
    </template>
  </BaseModal>
</template>

<style scoped></style>
